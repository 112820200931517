<template>
    <div :class="`wrapper  wrapper--210 m-3 p-2`">
      <div class="text-center p-2">
        <h3>
          Afleveringen van: {{ franchise }}, Jaar: {{ year }}, Maand: {{ month }}
        </h3>
        <span @click="previousMonth"><i class="fas fa-arrow-left"></i></span>
        <span class="text-center m-2">Maand: {{ month }}</span>
        <span @click="nextMonth"><i class="fas fa-arrow-right"></i></span>
      </div>
      <Loading v-if="loading" />
      <button class="m2 employee_download" @click="createExcel">Uitdraai maken</button>
      <table v-if="!loading" :class="`w-100 table--210 table__border--210`">
        <thead :class="`table__head--210`">
          <tr>
            <th class="table__head--text">Item</th>
            <th class="table__head--text">Verkoper</th>
            <th class="table__head--text">Soort</th>
            <th class="table__head--text">Merk en type</th>
            <th class="table__head--text">Jaar</th>
            <th class="table__head--text">Klant</th>
            <th class="table__head--text">Dagen</th>
            <th class="table__data--right table__head--text">Inkopen</th>
            <th class="table__data--right table__head--text">Kosten</th>
            <th class="table__data--right table__head--text">Verkopen</th>
            <th class="table__data--right table__head--text">Commissie</th>
            <th class="table__data--right table__head--text">Winst</th>
            <th class="table__data--right table__head--text">Perc</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, key) in deliveries_per_franchise"
            :key="key"
            :class="`table__row--color`"
          >
            <td class="table__cell--default">
              <ImageHover :itemnummer="item.nummer" :bu="item.bu" /> 
            </td>
            <td class="table__cell--default">{{ item.verkopernaam2 }}</td>
            <td class="table__cell--default">{{ item.soortnaam }}</td>
            <td class="table__cell--default">{{ item.merktype }}</td>
            <td class="table__cell--default">{{ item.regjaar }}</td>
            <td class="table__cell--default">{{ item.klantnaam }}</td>
            <td class="table__cell--default">{{ item.stadagen }}</td>
            <td class="table__data--right table__cell--default">€ {{ item.inkoopbedrag }}</td>
            <td class="table__data--right table__cell--default">€ {{ item.kostenbedrag }}</td>
            <td class="table__data--right table__cell--default">€ {{ item.verkoopbedrag }}</td>
            <td class="table__data--right table__cell--default">€ {{ item.commisiebedrag }}</td>
            <td class="table__data--right table__cell--default">€ {{ item.winst }}</td>
            <td class="table__data--right table__cell--default">{{ item.winstperc }} %</td>
          </tr>
        </tbody>
        <tfoot class="table__foot">
          <tr>
            <th class="table__foot--padding">{{ deliveries_per_franchise_total.aantal }}</th>
            <th class="table__foot--padding"></th>
            <th class="table__foot--padding"></th>
            <th class="table__foot--padding"></th>
            <th class="table__foot--padding"></th>
            <th class="table__foot--padding"></th>
            <th class="table__foot--padding"></th>
            <th class="table__data--right table__foot--padding">
              € {{ deliveries_per_franchise_total.inkoopbedrag }}
            </th>
            <th class="table__data--right table__foot--padding">
              € {{ deliveries_per_franchise_total.kostenbedrag }}
            </th>
            <th class="table__data--right table__foot--padding">
              € {{ deliveries_per_franchise_total.verkoopbedrag }}
            </th>
            <th class="table__data--right table__foot--padding">
              € {{ deliveries_per_franchise_total.commissie }}
            </th>
            <th class="table__data--right table__foot--padding">
              € {{ deliveries_per_franchise_total.winst }}
            </th>
            <th class="table__data--right table__foot--padding">
              {{ deliveries_per_franchise_total.winstperc }} %
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  </template>
  
  <script>
  import request from "@/functions/request.js";
  import moment from "moment";
  import Loading from "@/components/Loading.vue";
  import { subtractTime, addTime } from "@/functions/date.js";
  import ImageHover from "@/components/ImageHover.vue"
  import * as XLSX from "xlsx";

  export default {
    components: { Loading, ImageHover },
    props: ["franchise"],
    data: () => ({
      loading: null,
      deliveries_per_franchise: null,
      deliveries_per_franchise_total: null,
      name: null,
      first_week: null,
      last_week: null,
      full_date: moment(),
      month: null,
      year: null,
    }),
    created() {
      this.getData(this.franchise);
    },
    watch: {
      franchise(newFranchise) {
        this.getData(newFranchise);
      },
    },
    methods: {
      getData(franchise) {
        this.loading = true;
        this.month = moment(this.full_date).month() + 1;
        this.first_day = moment(this.full_date)
          .startOf("month")
          .format("YYYY-MM-DD");
        this.last_day = moment(this.full_date)
          .endOf("month")
          .format("YYYY-MM-DD");
        this.year = moment(this.full_date).year();
        request(
          `deliveries-per-month-per-franchise/${this.first_day}/${this.last_day}/${franchise}`,
          "GET"
        ).then(({ deliveries_per_franchise, deliveries_per_franchise_total }) => {
          this.deliveries_per_franchise = deliveries_per_franchise;
          this.deliveries_per_franchise_total = deliveries_per_franchise_total;
          this.loading = false;
        });
      },
  
      previousMonth() {
        this.full_date = subtractTime(1, this.full_date, "months", "YYYY/MM/DD");
        this.getData(this.franchise);
      },
  
      nextMonth() {
        this.full_date = addTime(1, this.full_date, "months", "YYYY/MM/DD");
        this.getData(this.franchise);
      },

      createExcel() {
      let sales_orders = [];
      this.deliveries_per_franchise.forEach((i) => {
        sales_orders.push({
          "Item": i.nummer,
          "Verkoper": i.naam,
          "Soort": i.soortnaam,
          "Merk en type": i.merktype,
          "Jaar": i.jaar,
          "Klant": i.klant,
          "Dagen": i.stadagen,
          "Inkopen": i.inkoopbedrag,
          "Kosten": i.kostenbedrag,
          "Verkopen": i.verkoopbedrag,          
          "Commissie": i.commisiebedrag,
          "Winst": i.winst,
          "Perc": i.winstperc,
        });
      });

      var telWS = XLSX.utils.json_to_sheet(sales_orders);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        wb,
        telWS,
        `Afleveringen_${this.franchise}_${this.month}_${this.year}`
      );
      XLSX.writeFile(
        wb,
        `Afleveringen_${this.franchise}_${this.month}_${this.year}.xlsx`
      );
    },
    },
  };
  </script>
  